body {
  background-color: rgb(39, 39, 39);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gameOver {
  margin: 20px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: whitesmoke;
  padding: 20px;
  background-color: rgb(53, 53, 53);
}

.cardTitle {
  font-size: xx-large;
  margin-bottom: 30px;
}

.cardText {
  margin-bottom: 20px;
}

.parent {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.turnContainer {
  color: white;
  display: flex;
  align-self: center;
}

.title {
  font-size: 15px;
  align-self: left;
  color: white;
  margin: 20px;
}

.turnTextBox {
  height: 60px;
}

.boardContainer {
  margin: 10px;
  padding: 10px;
}

.custom-drag-drop {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 4px;
}

.custom-item {
  display: flex;
  background-color: rgb(176, 177, 218);
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border-radius: 10px;
  text-transform: capitalize;
}

.custom-help-item-1 {
  display: flex;
  background-color: white;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border-radius: 10px;
  text-transform: capitalize;
  position: relative;
  animation-name: helpRight;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 500ms;
  margin: 5px;

}


.custom-help-item-2 {
  display: flex;
  background-color: white;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border-radius: 10px;
  text-transform: capitalize;
  position: relative;
  animation-name: helpLeft;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 500ms;
  margin: 5px;
}

.custom-help-item-3 {
  display: flex;
  background-color: rgb(0, 185, 99);
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border-radius: 10px;
  text-transform: capitalize;
  margin: 5px;
}

.custom-item-complete {
  display: flex;
  background-color: rgb(0, 185, 99);
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border-radius: 10px;
  text-transform: capitalize;
}

.custom-item-bg {
  display: flex;
  background-color: transparent;
  aspect-ratio: 1;
}

.custom-item-bg-failed {
  display: flex;
  background-color: rgb(100, 100, 100);
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border-radius: 10px;
  text-transform: capitalize;
}

.loading {
  width: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-25%, 0);
  position: absolute;
}


.lds-dual-ring:after {
  content: " ";
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent;
  animation: lds-dual-ring 1.2s ease-in infinite;
  ;
}


.modal {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: #272727;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  border-radius: 5px;

}

.help-grid {
  display: flex;
  flex-direction: row;
  height: 70px;
  padding: 2px;
  align-self: center;
  justify-content: center;
}

.title-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.helpButton {
  font-size: 15px;
  color: white;
  margin: 20px;
}

.help-div {
  height: 50vh;
}

.help-text {
  padding: 10px;
  color: white;
}

@media screen and (orientation:landscape) {
  .parentWrapper {
    max-width: 60vh;
    margin: auto;
  }
}

@keyframes helpLeft {
  0% {
    background-color: rgb(255, 255, 255);
    left: 0;
  }

  20% {
    background-color: rgb(255, 255, 255);
    left: 0;
  }

  40% {
    background-color: rgb(255, 255, 255);
    left: 0;
  }

  50% {
    background-color: rgb(0, 185, 99);
    left: -70px;
  }

  75% {
    background-color: rgb(0, 185, 99);
    left: -70px;
  }

  100% {
    background-color: rgb(0, 185, 99);
    left: -70px;
  }
}

@keyframes helpRight {
  0% {
    background-color: rgb(255, 255, 255);
    left: 0;
  }

  20% {
    background-color: rgb(255, 255, 255);
    left: 0;
  }

  40% {
    background-color: rgb(255, 255, 255);
    left: 0;
  }

  50% {
    background-color: rgb(0, 185, 99);
    left: 70px;
  }

  75% {
    background-color: rgb(0, 185, 99);
    left: 70px;
  }

  100% {
    background-color: rgb(0, 185, 99);
    left: 70px;
  }
}




@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0);
  }
}